import React, { useEffect } from "react"
import Header from "../components/Header"
import ImageBanner from "../components/ImageBanner"
import Footer from "../components/Footer"
import $ from "jquery"

const ServiceBox = ({ title, description, image }) => {
  return (
    <div className="tw-service-box-list text-center">
      <div className="service-list-bg service-list-bg-2 d-table">
        <div className="service-list-icon d-table-cell">
          <img src={image} alt="" className="img-fluid"/>
        </div>
      </div>
      <h3><a href="">{title}</a></h3>
      <p>{description}</p>
    </div>
  )
}

const MoreServices = () => {
  return (
    <section id="main-container" className="main-container">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-12">
            <div className="section-heading">
              <h2>
                Mas <span>Servicios</span>
              </h2>
              <span className="animate-border tw-mt-30 tw-mb-40"></span>
              <p>Las más sofisticadas soluciones de la insdustria al alcance de tus manos.</p>
            </div>
          </div>

          <div className="col-lg-9 col-md-12">

            <div className="service-list-carousel owl-carousel">

              <ServiceBox
                title={"Ludopatia"}
                description={"Herramienta en linea de gestión, control, y consulta de jugadores auto-excluidos, excluidos, y de mal comportamiento."}
                image={"images/icon/feature2.png"}
              />

              <ServiceBox
                title={"Casino Online"}
                description={"Plataforma segura, escalable y personalizable con acceso a uno de los mejores contenidos de\n" +
                "                  juego en linea. Integración a métodos de pago, gestión de jugadores, marketing, y\n" +
                "                  más..."}
                image={"images/icon/feature1.png"}
              />


              <ServiceBox
                title={"UIF"}
                description={"Registro y control de actividades, ganadores, y premios para la prevención de lavado de activos "}
                image={"images/icon/feature3.png"}
              />

              <ServiceBox
                title={"Analytics"}
                description={"Integra todos tus sistemas de información, analiza los datos y visualiza resultados como\n" +
                "                  nunca antes los viste. "}
                image={"images/icon/feature4.png"}
              />

            </div>

          </div>
        </div>
      </div>
    </section>
  )
}


const Integation = () => {
  return (
    <section id="tw-service-value" className="tw-service-value bg-offwhite">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-12">
            <div className="tw-value-desc">
              <h2 className="column-title">Integración</h2>
              <span className="animate-border border-offwhite tw-mt-20 tw-mb-35"></span>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tincidunt urna lectus. Phasellus
                felis purus, molet.
              </p>
              <a href="#" className="btn btn-primary tw-mt-20">Comienza ahora</a>
            </div>
          </div>
          <div className="col-md-4 col-lg-3 text-center">
            <div className="percent-area">
              <div className="chart" data-percent="25">
                <p className="percent">25%</p>
                <small></small>
              </div>
              <p>Casino Online</p>
            </div>
          </div>
          <div className="col-md-4 col-lg-3 text-center">
            <div className="percent-area">
              <div className="chart" data-percent="50">
                <p className="percent">50%</p>
                <small></small>
              </div>
              <p>UIF</p>
            </div>
          </div>
          <div className="col-md-4 col-lg-3 text-center">
            <div className="percent-area">
              <div className="chart" data-percent="100">
                <p className="percent">100%</p>
                <small></small>
              </div>
              <p>Bussiness Intelligence</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default function Promociones() {

  // useEffect(() => {
  //   /* Service List Box Slider */
  //   if ($(".service-list-carousel").length > 0) {
  //     $(".service-list-carousel").owlCarousel({
  //       items: 3,
  //       loop: true,
  //       margin: 10,
  //       autoplay: true,
  //       nav: true,
  //       navText: ["<i class=\"icon icon-arrow-left\"></i>", "<i class=\"icon icon-arrow-right\"></i>"],
  //       dots: false,
  //       autoplayTimeout: 5000,
  //       autoplayHoverPause: true,
  //       mouseDrag: true,
  //       responsiveClass: true,
  //       smartSpeed: 900,
  //       responsive: {
  //         0: {
  //           items: 1
  //         },
  //         600: {
  //           items: 2
  //         },
  //         1000: {
  //           items: 3,
  //           margin: 5
  //         }
  //       }
  //     })
  //   }
  //
  // }, [])


  return (
    <div>
      <Header/>

      <ImageBanner
        title={"Promociones"}
        text1={"Inicio"}
        text2={"Promociones"}
        backgroundImage={"url(images/banner/banner4.png)"}
      />


      <section id="main-container" className="main-container">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <div className="section-heading">
                <h2>
                  <small>Mejoramos la experiencia</small>
                  Te damos <span>Herramientas</span>
                </h2>
                <span className="animate-border tw-mt-20 tw-mb-40 mr-auto ml-auto"></span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 align-self-md-center">
              <img src="images/services/single_service_img3.png" alt="" className="img-fluid analytics-img"/>
            </div>
            <div className="col-md-5 ml-auto align-self-center">
              <div className="tw-web-analytics-content">
                <i className="icon icon-question-circle"></i>
                <h3>Nuestro sistema</h3>
                <span className="bottom-border tw-mt-20 tw-mb-30"></span>
                <p>
                  Plataforma integral de rápido despliegue enfocada en la expansión de su negocio. Incluye el
                  mejor contenido de juegos online, gestión de usuarios, pagos, reportes, y personalización de su
                  marca.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 align-self-center">
              <div className="tw-web-analytics-content">
                <i className="icon icon-coins-1"></i>
                <h3>Lo que nos diferencia</h3>
                <span className="bottom-border tw-mt-20 tw-mb-30"></span>
                <p>
                  La experiencia en la industria nos ha llevado a crear una plataforma adaptada a operaciones
                  locales que se enfoca en la experiecia del jugador y en el máximo entretenimiento.
                </p>
              </div>
            </div>
            <div className="col-md-6 ml-auto align-self-md-center">
              <img src="images/services/single_service_img2.png" alt="" className="img-fluid analytics-img"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 align-self-md-center">
              <img src="images/services/single_service_img1.png" alt="" className="img-fluid analytics-img"/>
            </div>
            <div className="col-md-5 ml-auto align-self-center">
              <div className="tw-web-analytics-content">
                <i className="icon icon-target"></i>
                <h3>Cambios significativos que notarás</h3>
                <span className="bottom-border tw-mt-20 tw-mb-30"></span>
                <p>
                  Ofrecer nuevas alternativas de juego te permitirá expandir tu contenido, aumentar la
                  satisfacción de tus clientes, y llegar a nuevos segmentos de mercado.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <MoreServices/>

      <section id="tw-call-to-action" className="tw-call-to-action">
        <div className="container">
          <div className="call-action-bg-pattern">
            <img src="images/services/call_to _action_img1.png" alt=""/>
            <img src="images/services/call_to _action_img2.png" alt=""/>
          </div>
          <div className="row">
            <div className="col text-center">
              <h2 className="column-title">
                <small>Nos comprometemos con tus metas</small>
                Evoluciona tu <span>sala de juego</span>
              </h2>
              <a href="#" className="btn btn-primary tw-mt-20 btn-lg">Comencemos</a>
            </div>
          </div>
        </div>
      </section>

      <Footer/>
    </div>
  )
}
